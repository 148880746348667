<template>
  <div>
    <canvas id="canvas" width="600" height="600" style="border: solid black 1px">
      Ваш браузер не поддерживает элемент canvas.
    </canvas>
    <pre>
      {{ ongoingTouches }}
      X {{ startPointX }}
      Y {{ startPointY }}
      currentX {{ currentX }}
      currentY {{ currentY }}
      DX {{ deltaX }}
      DY {{ deltaY }}
    </pre>
  </div>
</template>

<script>
export default {
  name: "TestPage",
  data: function () {
    return {
      elem: document.querySelector("body"),
      ongoingTouches: [],
      startPointX: null,
      startPointY: null,
      currentX: null,
      currentY: null,
    };
  },
  computed: {
    deltaX: function () {
      if (this.currentX === null) return 0;
      return this.startPointX - this.currentX;
    },
    deltaY: function () {
      if (this.currentY === null) return 0;
      return this.startPointY - this.currentY;
    },
  },
  mounted() {
    this.startup();
  },
  methods: {
    startup: function () {
      this.elem.addEventListener("touchstart", this.handleStart, false);
      this.elem.addEventListener("touchend", this.handleEnd, false);
      this.elem.addEventListener("touchcancel", this.handleEnd, false);
      this.elem.addEventListener("touchmove", this.handleMove, false);
    },
    handleStart: function (evt) {
      evt.preventDefault();
      const touches = evt.changedTouches;
      if (touches.length > 1) return;
      this.startPointX = touches[0].pageX;
      this.startPointY = touches[0].pageY;
    },
    handleEnd: function () {
      this.currentX = this.currentY = this.startPointX = this.startPointY = null;
    },
    handleMove: function (evt) {
      evt.preventDefault();
      const touches = evt.changedTouches;
      this.currentX = touches[0].pageX;
      this.currentY = touches[0].pageY;
    },
  },
};
</script>

<style lang="sass">
#canvas
  width: 600px
  height: 400px
  background: #fff
  top: 50%
  left: 50%
  position: absolute
  transform: translate(-50%, -50%)
</style>
